import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'

import '../scss/styles.scss'

const Layout = ({ location, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={({ site: { siteMetadata: { title, description, keywords, url }}}) => (
      <div className="wrapper">
        <Helmet>
          <title>{ title || 'Shed Express' }</title>
          <meta name="title" content={ title } />
          <meta name="description" content={ description } />
          <meta name="keywords" content={ keywords } />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={ title } />
          <meta property="og:description" content={ description } />
          <meta property="og:url" content={ url } />

          <link rel="icon" href="images/favicon.ico" />
        </Helmet>

        <Header />
        <div className="container">
          {children}
        </div>
        <footer>
          ShedExpress inc. Designed in 2019. All rights reserved.
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
