import { Link } from 'gatsby'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'


class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    document.querySelector('.header-menu').classList.toggle('opened')
  }

  render() {
    return (
      <div className="header-wrapper">
        <div className="header">
          <div className="header-logo" to="/">
            <Link to="/">
              <img src="images/logo.svg" alt="Shedexpress Logo" />
            </Link>
            <div>
              <div>shedexpress</div>
              <a className="header-phone" href="tel:+7327021622">(732) 702-1622</a>
            </div>
          </div>

          <img
            className="header-menu--icon"
            src="images/menu.svg"
            alt="Mobile menu icon"
            onClick={ this.toggleMenu }
          />

          <nav className="header-menu">
            <AnchorLink className="header-menu--item" onClick={ this.toggleMenu } href="#sheds">Sheds</AnchorLink>
            <AnchorLink className="header-menu--item" onClick={ this.toggleMenu } href="#accessories">Accessories</AnchorLink>
            <AnchorLink className="header-menu--item" onClick={ this.toggleMenu } href="#gallery">Gallery</AnchorLink>
            <AnchorLink className="header-menu--item" onClick={ this.toggleMenu } href="#contact">Contact</AnchorLink>
            <a className="header-menu--item" href="docs/Shed_Express.pdf" target="_blank">Brochure</a>
            <a className="header-menu--item" href="tel:+7327021622">(732) 702-1622</a>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header
